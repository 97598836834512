<template>
  <section>
    <h1 class="section-header">Export</h1>
    <div class="row mt-3">
      <div class="col-md-6">
        <monthly-report title="Körjournal" type="drivelog"></monthly-report>
      </div>
      <div class="col-md-6">
        <monthly-report title="Fordon" type="vehicles"></monthly-report>
      </div>
    </div>
  </section>
</template>

<script>
import MonthlyReport from "@/components/overview/MonthlyReport";
export default {
  components: {MonthlyReport}
}
</script>

<style scoped>

</style>
