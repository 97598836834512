<template>
  <section class="overview-stats">
    <h1 class="section-header mb-4">Statistik</h1>
    <div class="box box--gray items">
      <stats-card :options="stats.brand" type="Märken"></stats-card>
      <stats-card :options="stats.fuel" type="Drivmedel"></stats-card>
      <stats-card :options="stats.mileage" type="Körningar"></stats-card>
    </div>
  </section>
</template>

<script>
import StatsCard from "@/components/overview/StatsCard";

import statsHttp from '@/http/statistics'

export default {
  components: {StatsCard},
  data() {
    return {
      stats: {
        brand: [],
        fuel: [],
        mileage: []
      }
    }
  },
  methods: {
    sortItems(first, second) {
      return second.value - first.value
    },
    mapStats(type) {
      return item => ({name: this.mapTripTypeName(item[type]), value: Math.round(item.percentage)})
    },
    mapTripTypeName(value) {
      if(value === 'business') return 'Tjänst'
      if(value === 'private') return 'Privat'
      return value
    }
  },
  async created() {
    try {
      const stats = await statsHttp.getStats();
      this.stats.brand = stats.vehicleBrandPercentage.stats.map(this.mapStats('brand')).sort(this.sortItems)
      this.stats.fuel = stats.vehicleFuelTypePercentage.stats.map(this.mapStats('fuelType')).sort(this.sortItems)
      this.stats.mileage = stats.mileageByTripCategoryPercentage.stats.map(this.mapStats('category')).sort(this.sortItems)
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
</style>
