<template>
  <div class="box box--white stat">
    <h3 class="stat-title">{{ type }}</h3>
    <div class="mt-4">
      <div class="stat-item" v-for="(option, index) in options.slice(0, 4)" :key="index">
        <div class="value">{{ option.name }} <b>{{ option.value }}%</b></div>
        <div class="progress">
          <div class="progress--active" :style="{'width': option.value + '%'}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    options: Array
  }
}
</script>

<style lang="scss" scoped>
.stat-title {
  margin: 0 0 10px;
  color: var(--color-secondary);
  font-weight: 700;
  font-size: 18px;
}
.stat-item {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  margin-bottom: 10px;
  font-size: 15px;

  .value {
    font-weight: 500;
    flex: 1;
  }
  .progress {
    border-radius: 4px;
    width: 50%;
    height: 5px;
    background: var(--color-gray-light);
  }
  .progress--active {
    border-radius: 4px;
    background: var(--color-secondary);
    height: 100%;
  }
}
</style>
