<template>
  <div class="overview">
    <overview-exports></overview-exports>
    <overview-stats class="mt-5"></overview-stats>
<!--    <event-log class="mt-5"></event-log>-->
  </div>
</template>

<script>
import OverviewExports from "@/containers/OverviewExports";
import OverviewStats from "@/containers/OverviewStats";
import EventLog from "@/components/overview/EventLog";

import axios from 'axios';
import {userRolesTypes} from "@/utils/user-roles";
export default {
  components: {EventLog, OverviewStats, OverviewExports},
  mounted() {
  },
  created() {
    if(this.$store.getters.getUserRoles.includes(userRolesTypes.RESELLER_ADMIN) || this.$store.getters.getUserRoles.includes(userRolesTypes.SUPER_ADMIN)) {
      this.$router.push('/companies')
    }
  }
}
</script>

<style lang="scss" scoped>
.overview {
}
</style>
