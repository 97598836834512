<template>
  <div class="event-log">
    <h1 class="section-header mb-4">Händelser</h1>
    <div class="box box--outline" style="position: relative; overflow: hidden">
      <app-table :head="head">
        <tr v-for="(log, index) in logs" :key="index">
          <td>
            <alert-icon v-if="log.type === 'alert'"></alert-icon>
            <warning-icon v-if="log.type === 'warning'"></warning-icon>
          </td>
          <td>{{ log.vehicle }}</td>
          <td>{{ log.message }}</td>
          <td>{{ log.time }}</td>
        </tr>
      </app-table>
      <div class="load-more-overlay">
        <app-button class="btn--blue">Show all</app-button>
      </div>
    </div>
  </div>
</template>

<script>
import AppTable from "@/components/shared/AppTable";
import AlertIcon from "@/assets/icons/alert-circle-outline.svg?inline";
import WarningIcon from "@/assets/icons/warnig-outline.svg?inline";
import AppButton from "@/components/shared/AppButton";

export default {
  components: {AppButton, AppTable, AlertIcon, WarningIcon},
  data() {
    return {
      head: [
        {name: '', width: 5},
        {name: 'Fordon', width: 20},
        {name: 'Händelse', width: 55},
        {name: 'Tidpunkt', width: 35},
      ],
      logs: [
        {type: 'alert', vehicle: 'BMW M5 (ABC 123)', message: 'Tappat anslutning', time: '28 March 14:49'},
        {type: 'warning', vehicle: 'BMW M5 (ABC 123)', message: 'Utanför säkerhetszon', time: '28 March 14:49'},
        {type: 'alert', vehicle: 'BMW M5 (ABC 123)', message: 'Tappat anslutning', time: '28 March 14:49'},
        {type: 'warning', vehicle: 'BMW M5 (ABC 123)', message: 'Tappat anslutning', time: '28 March 14:49'},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.load-more-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 150px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--color-gray-xLight));
  border-radius: 30px 0 0 30px;
  @include flexbox;
  @include justify-content(center);
  @include align-items(flex-end);
  padding-bottom: 20px;
}
</style>
