<template>
  <article class="export box box--outline">
    <div class="export__label">
      {{ title }}
    </div>
    <div class="export__actions d-flex mt-3">
      <!--      <app-button class="btn&#45;&#45;transparent" icon="search-outline">Förhandsgranska</app-button>-->
      <date-picker range-separator=" - " range style="width: 100%" class="mt-2" type="date" v-model="timespan"
                   valueType="format"></date-picker>
      <app-button class="btn--transparent mt-2" icon="excel-outline" @click="getReports">Excel</app-button>

    </div>
  </article>
</template>

<script>
import InputField from "@/components/shared/InputField";
import AppButton from "@/components/shared/AppButton";
import DatePicker from 'vue2-datepicker';
import moment from "moment";

import statsHttp from '@/http/statistics'

export default {
  props: ['title', 'type'],
  components: {AppButton, InputField, DatePicker},
  data() {
    return {
      timespan: ['2021-05-07', '2021-05-20']
    }
  },
  methods: {
    async getReports() {
      try {
        const data = await statsHttp.getReports(this.type, this.timespan)
        const fileName = this.type === 'drivelog' ? 'Körjournal ' + this.timespan.join('__') : 'Fordonsexport ' + this.timespan.join('__')
        this.downloadExel(data, fileName)
      } catch (e) {
      }
    },
    downloadExel(data, fileName) {
      const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    }
  },
  created() {
    const today = new Date();
    const from = moment(today).subtract(7, 'd').format('YYYY-MM-DD');
    const to = moment(today).format('YYYY-MM-DD');
    this.timespan = [from, to];
  }
}
</script>

<style lang="scss" scoped>
.export {
  &__label {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .export__actions {
    gap: 20px
  }
}
</style>
