import axios from "axios";

export default {
  getStats() {
    return axios.get('/dashboard/stats')
  },
  getReports(type, date) {
    return axios.get(`/reports/${type}/excel?dateFrom=${date[0]}&dateTo=${date[1]}`, {responseType: 'blob'})
  }
}
